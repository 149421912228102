/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	header.site .buttons .btn{margin-top: 25px; }
	footer.site .smbuttons{text-align: right;}
	.navbar-form #search .input-group{width:360px}
	.card .btn.btn-primary{
		position: absolute;
		bottom: 40px;
		right: 30px;
	}
	.useraction{float:right;}

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	#welcome-search:not(.short){padding: 100px 0 140px 0;}
	#welcome-search.short{padding: 60px 0 0;}
	#pratik-bilgi{overflow:hidden}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}



/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

}

/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {


}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {

	.navbar-brand{float: none;display: block; text-align: center;}
	footer.site{
		p{margin-bottom: 10px; text-align: center;}
		.ig-b-{margin-top: 10px;}


	}
	header.site{
		.navbar-form{
			margin-top: 0;
		}
	}
	header.site .buttons{
		text-align: center;
		.btn{margin-bottom: 15px;width:90%;}
	}

	#contest-welcome .btn-labeled:first-of-type{margin-bottom: 10px;}

}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {

}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 320px) {

}