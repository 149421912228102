/**
 * bootstrap carousel
 */
.carousel{
	.carousel-control {
		background:transparent !important;
	}
	.item{height:500px;min-height:500px;max-height:500px}
}

/*http://www.cssscript.com/3d-perspective-carousel-with-css3-transforms/*/
@media only screen and (max-width : 992px) {
	.custom-carousel {
		width: 200px;
		height: 200px;
		max-width:100%;
		overflow:hidden;
		margin: 30px auto;
		-webkit-perspective: 500;
		perspective: 500;
	}

	.custom-carousel-content {
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
		-webkit-transform: translateZ(-182px) rotateY(0);
		transform: translateZ(-182px) rotateY(0);
		-webkit-animation: carousel 17s infinite cubic-bezier(1, 0.015, 0.295, 1.225) forwards;
		animation: carousel 17s infinite cubic-bezier(1, 0.015, 0.295, 1.225) forwards;
	}

	.custom-carousel-item {
		position: absolute;
		top: 0;
		left: 0;
		width: 200px;
		height: 200px;
		border-radius: 6px;
	}

	.custom-carousel-item:nth-child(1) {
		-webkit-transform: rotateY(0) translateZ(182px);
		transform: rotateY(0) translateZ(182px);
	}

	.custom-carousel-item:nth-child(2) {
		-webkit-transform: rotateY(120deg) translateZ(182px);
		transform: rotateY(120deg) translateZ(182px);
	}

	.custom-carousel-item:nth-child(3) {
		-webkit-transform: rotateY(240deg) translateZ(182px);
		transform: rotateY(240deg) translateZ(182px);
	}

	@-webkit-keyframes 
	carousel {  
		0%, 17.5% {
			-webkit-transform: translateZ(-182px) rotateY(0);
			transform: translateZ(-182px) rotateY(0);
		}
		27.5%, 45% {
			-webkit-transform: translateZ(-182px) rotateY(-120deg);
			transform: translateZ(-182px) rotateY(-120deg);
		}
		55%, 72.5% {
			-webkit-transform: translateZ(-182px) rotateY(-240deg);
			transform: translateZ(-182px) rotateY(-240deg);
		}
		82.5%, 100% {
			-webkit-transform: translateZ(-182px) rotateY(-360deg);
			transform: translateZ(-182px) rotateY(-360deg);
		}
	}
	@keyframes 
	carousel {  
		0%, 17.5% {
			-webkit-transform: translateZ(-182px) rotateY(0);
			transform: translateZ(-182px) rotateY(0);
		}
		27.5%, 45% {
			-webkit-transform: translateZ(-182px) rotateY(-120deg);
			transform: translateZ(-182px) rotateY(-120deg);
		}
		55%, 72.5% {
			-webkit-transform: translateZ(-182px) rotateY(-240deg);
			transform: translateZ(-182px) rotateY(-240deg);
		}
		82.5%, 100% {
			-webkit-transform: translateZ(-182px) rotateY(-360deg);
			transform: translateZ(-182px) rotateY(-360deg);
		}
	}
}
@media only screen and (min-width : 992px) {
	.custom-carousel {
		width: 320px;
		height: 320px;
		margin: 30px auto;
		-webkit-perspective: 1600;
		perspective: 1600;

	}

	.custom-carousel-content {
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-transform-style: preserve-3d;
		transform-style: preserve-3d;
		-webkit-transform: translateZ(-220px) rotateY(0);
		transform: translateZ(-220px) rotateY(0);
		-webkit-animation: carousel 17s infinite cubic-bezier(1, 0.015, 0.295, 1.225) forwards;
		animation: carousel 17s infinite cubic-bezier(1, 0.015, 0.295, 1.225) forwards;
	}

	.custom-carousel-item {
		position: absolute;
		top: 0;
		left: 0;
		width: 320px;
		height: 320px;
		border-radius: 6px;
	}

	.custom-carousel-item:nth-child(1) {
		-webkit-transform: rotateY(0) translateZ(220px);
		transform: rotateY(0) translateZ(220px);
	}

	.custom-carousel-item:nth-child(2) {
		-webkit-transform: rotateY(120deg) translateZ(220px);
		transform: rotateY(120deg) translateZ(220px);
	}

	.custom-carousel-item:nth-child(3) {
		-webkit-transform: rotateY(240deg) translateZ(220px);
		transform: rotateY(240deg) translateZ(220px);
	}

	@-webkit-keyframes 
	carousel {  
		0%, 17.5% {
			-webkit-transform: translateZ(-220px) rotateY(0);
			transform: translateZ(-220px) rotateY(0);
		}
		27.5%, 45% {
			-webkit-transform: translateZ(-220px) rotateY(-120deg);
			transform: translateZ(-220px) rotateY(-120deg);
		}
		55%, 72.5% {
			-webkit-transform: translateZ(-220px) rotateY(-240deg);
			transform: translateZ(-220px) rotateY(-240deg);
		}
		82.5%, 100% {
			-webkit-transform: translateZ(-220px) rotateY(-360deg);
			transform: translateZ(-220px) rotateY(-360deg);
		}
	}
	@keyframes 
	carousel {  
		0%, 17.5% {
			-webkit-transform: translateZ(-220px) rotateY(0);
			transform: translateZ(-220px) rotateY(0);
		}
		27.5%, 45% {
			-webkit-transform: translateZ(-220px) rotateY(-120deg);
			transform: translateZ(-220px) rotateY(-120deg);
		}
		55%, 72.5% {
			-webkit-transform: translateZ(-220px) rotateY(-240deg);
			transform: translateZ(-220px) rotateY(-240deg);
		}
		82.5%, 100% {
			-webkit-transform: translateZ(-220px) rotateY(-360deg);
			transform: translateZ(-220px) rotateY(-360deg);
		}
	}
}