/**
* Soru yarışmasıyla ilgili css geliştirmelerini içeren dosya.
*/
.usye_icon{
	position:fixed;
	left:30px;
	bottom:30px;
	cursor: pointer;
	z-index:9999;
	&:not(&.mobile){
		font-size:105px;
		color:$brand-primary;
		i{@include transition(.2s)}
		&:hover i{
			transform:rotate(45deg); 
		}
	}
	&.mobile{
		font-size:40px;
		bottom:14px;
		left:auto;
		right:14px;
		padding:0px 15px;
	}
}

#question-form{
	margin:15px auto auto auto;
	width:350px;
	max-width:100%;
	text-align: left;
	input.form-control {display:block !important}
	input[type="radio"] {
		display:none;
	}
	input[type="radio"] + label {
		font-weight:300;
		color:#797979;
		font-size: 16px;
	}
	input[type="radio"] + label span {
		display:inline-block;
		width:19px;
		height:19px;
		margin:-1px 4px 0 0;
		vertical-align:middle;
		cursor:pointer;
		border-radius:  100%;
		border:2px solid $brand-primary;
		position: relative;
		&:before{
			content:'';
			position: absolute;
			width:9px;
			height:9px;
			left:3px;top:3px;
			border-radius:100%;
		}
	}
	input[type="radio"]:checked + label span{
		&:before{
			background:$brand-primary;
		}
	}
	input[type="radio"] + label span,
	input[type="radio"]:checked + label span:before {
		@include transition(background-color 0.4s linear);
	}
}
.question{
	h3{
		margin-top:0px;
		line-height:33px;
		border-bottom:1px solid #eee;
		padding-bottom: 10px;
	}
}

.cancel.custom{
	background:transparent !important;
	padding:0px;
	position: absolute;
	top: -50px;
	right: 0px;
	color: $gray-light;
}