@import "variables";
@import "index"; // multi brand colors
@import "bootstrap";
@import "labeled-buttons";
@import "uploader";
@import "custom-carousel";

@import "question";

body{
	font-family: 'Raleway', sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
}
a.default{
	color: inherit;
	&:hover, &:focus { color:$brand-primary;}
	&.nohover:hover{
		color:inherit;
		text-decoration: underline;
	}
}

a,button{transition: all 0.5s ease; }
small{vertical-align:text-top}
.btn-ghost{
	border: 2px solid $brand-secondary;
	color: inherit;
}

.vertical-center{
	display:flex;
	justify-content:flex-end;
	align-items:center;
}

.counter{
	position:fixed;
	bottom:0px;
	background:$brand-primary;
	color:#fff;
	padding:20px;
	text-align: center;
	left:0px;right:0px;display:inline-block;
	width:450px;
	margin:auto;
	z-index:999999;
	border-top-left-radius:3px;
	border-top-right-radius:3px;
	font-size:16px;
}

.btn-secondary {
  @include button-variant(#fff,$brand-secondary, $brand-secondary);
}
.btn-lg{border-radius:3px;}
.well{box-shadow:none;border-radius:0px}

.bg-overlay{content: ' '; position: absolute; left: 0; right: 0; top: 0; bottom: 0; @include gradient-vertical(rgba(59,67,93,1),transparent,0%,130%)}
.bg-image{
	background-image:url("../images/home-bg.png");
	background-position:center;
	background-repeat:no-repeat;
	background-size: cover;
	background-attachment: scroll;
	position: relative;
	color:#fff;

	h1,h2,h3,h4,h5{margin: 0;}
	&.intro{padding:30px 0;}
	&.site{
		.navbar-brand{
			color:#fff;
			 &:hover{}
		}
	}
}
header.site{
	h1{margin:0}
	position: relative;
	.navbar{z-index:1000}
	.buttons .btn{
		padding: 10px 15px;
		font-weight: bold;
		font-size: 13px;
	}
}
.navbar-toggle{background-color: $brand-primary; }

.navbar-brand{
	font-size:35px; margin-left: 0px !important; padding:28px 0; font-weight: bold; 
	i{color:$brand-secondary; @include transition(.3s); position: relative;  top: 1px; left: -8px;}
	&:hover i{
		transform:rotate(45deg); 
	}
}
.navbar-brand-centered {
	position: absolute;
	left: 50%;
	display: block;
	width: 280px;
	text-align: center;
	background-color: transparent;
	font-weight: bold;

}
.navbar>.container .navbar-brand-centered, 
.navbar>.container-fluid .navbar-brand-centered {
	margin-left: -140px;
}

.bigintro{
	color: #fff;
	position: relative;
	border-bottom:5px solid $gray-lighter;
	&:not(.short){padding: 30px 0;}
	&.short{padding: 25px 0 0;}
	
	h2{margin-bottom: 20px; margin-top: 0;}
	h4{margin-bottom: 30px;}
	
	.content{
		text-align: center;
	}

	.btn.search{padding: 10px 35px}
	
}

.navbar-form{
	#search{
		.input-group{}
		ul li{font-size: 12px !important;}
	}
}

#search{
	position: relative;
	.results{
		position: absolute;
		background: #fff;
		color: #333;
		margin-top: 5px;
		border-radius: 4px;
		overflow: hidden;
		z-index:99999;
		box-shadow:0px 8px 28px #777;
		width:100%;
		display:none;
		ul{margin: 0px;}
		ul li{
			padding: 13px;
			font-size: 17px;
			&:not(:last-child){
				border-bottom: 1px solid #eee;
			}
			&:hover{
				background:#eee;
			}
			a{text-decoration: none;}
		}
	}
}

.page{
	background:#fff url(../images/bg-shapes.png) 20px 20px;
	background-repeat-x:no-repeat;
	padding-top:$line-height-computed;

	&#home{
	
	}
	&#subpage{
		.subsection{padding-bottom: 10px; border-bottom: 1px solid #ddd; margin-bottom: 30px;}

		.content{
			background:#fff;
			padding:25px 0;
			margin-bottom: 20px;
			font-size:15px;
			line-height:23px;
			h2{margin:0px;font-weight:700}
			.sub-title{background:$brand-primary;color:#fff;padding:15px;margin: 20px 0px;}
			div.sub-title{margin:30px 0px}
			ul{
				li{
					position: relative;
					padding-left:17px;
					line-height: 30px;
					&:before{
						content:'';
						position: absolute;
						width:10px;height:10px;
						top:9px;left:0px;
						background:$brand-primary
					}
				}
			}
			.prospectus{
				border-top:5px solid $brand-primary;
				border-bottom:5px solid $brand-primary;
				display:none;
				h3{margin:0px}
				img{margin:20px 0px}
			}
		}
	}
	&#meme{
		h2{
			img{vertical-align:middle;margin-top:-5px}
		}
		.content{
			text-align: center;
			position: relative;
			img{
				width:700px;
				max-width:100%;
			}
			.share{
				margin:10px 3px 0px;
				display: inline-block;
				&.twitter{color:$mbc-twitter}
				&.facebook{color:$mbc-facebook}
			}
		}
	}
	&#submit{
		.submitbox{
			width:400px;
			max-width:100%;
			min-height:400px;
			margin-top:49px;
			img{width:100%;}
			input[type="file"]{
				opacity:0
			}
			.btn{margin-top:10px;}
			.form-group{display:none}
		}
		.preview{
			position: relative;
			height: 375px;
			width: 375px;
			max-width:100%;
			.img{
				width:100%;
				height:375px;
				background-size:cover;
				background-position: center;
				background-color:#f1f1f1;
			}
			.red-strip{
				width:100%;
				font:30px 'Impact','Arial';
				font-weight: bold;
				background:red;
				color:#fff;
				padding:10px;
				text-align: center;
				position: absolute;
				bottom: 0;
			}
			label{
				color:$gray-dark;
				font-size:21px;
				position: absolute;
				background:#eee;
				z-index:99999999;
				top:-49px;right:0px;left:0px;
				cursor:pointer;
				padding: 10px;
			}
		}
	}
	&#gallery{
		.item{
			margin-bottom: 30px;
			a{
				position: relative;
				display: block;
				.name{
					background:$gray-lighter;
					display: block;
					color:$brand-primary;
					font-size:18px;
					text-align: center;
					padding:8px 0px;
				}
			}
		}
	}
	&#rules{
		font-size:15px;
		line-height:1.7em;
		ul li{margin-bottom: 10px;}
		h3{font-weight: bold;}
	}
}

.all-articles{
	padding-bottom: 20px;

}

#contest-welcome{
	h3{font-weight: 700; line-height: 1.4em; margin-bottom: 10px;}
	h4{line-height: 1.4em; margin-bottom:30px}
}

// --------
// --------
// FOOTER
// --------
// --------


#pratik-bilgi{
	background-color: $brand-secondary;
	color:#fff;
	padding-bottom: 20px;
	//margin-top: 20px;
	
	.uvula{
		img{margin-top: -15px;}
		@include transition(.3s);
	}
	
}

footer.site{
	background-color: #fff;
	color:$brand-primary;
	p{margin-bottom: 0;}
	>div{padding:15px 0;}
	a{color:inherit}

	.smbuttons{text-align: center;}
}

.btn-like{
	position: absolute;
	left:0px;right:0px;
	background:rgba(0,0,0,.6);
	color:#fff;
	padding:7px 14px;
	font-size:30px;
	i{cursor: pointer;}
	.fa-heart{color:$mbc-youtube}
	.count{
		font-size:19px;
		position: relative;
		top:-3px;
	}
	&.bottom{bottom:0px;}
	&.top{top:0px;}
}


// --------
// --------
// OTHERS
// --------
// --------

/* http://bootsnipp.com/snippets/featured/material-cards */
.card {
	margin-bottom: $line-height-computed;
	box-sizing: border-box;
	background-clip: padding-box;
	background:#ededed;

	.btn.btn-primary{
		margin-top: 15px; 
		font-weight: bold; 
   	}

	span.card-title {color: #fff; font-size: 24px; font-weight: 300; text-transform: uppercase; }

	.card-image {
		position: relative; overflow: hidden;

		img {border-radius: 2px 2px 0 0; background-clip: padding-box; position: relative; z-index: 99; }
		span.card-title {position: absolute; bottom: 0; left: 0; padding: 16px; z-index:100}

	}

	.card-content {
		padding: 10px 15px 20px 15px; border-radius: 0 0 2px 2px; background-clip: padding-box; box-sizing: border-box;
		h3{margin-top: 10px;color:$brand-primary; line-height: 1.4em;}
		p {margin: 0; color: #4f4f4f; line-height: 1.6em; margin-bottom: 30px; }
		span.card-title {line-height: 48px;}
	}

	.card-action {
		border-top: 1px solid rgba(160, 160, 160, 0.2); padding: 16px;

		a {color: $brand-secondary; margin-right: 16px; transition: color 0.3s ease; text-transform: uppercase; font-weight: bold; }
		a:hover {color: darken($brand-secondary,10%); text-decoration: none;
		}
	}
	
}

/* footer social icons */
/* http://bootsnipp.com/snippets/n887z */
ul.social-network {list-style: none; display: inline; margin:0; padding: 0; float: right}
ul.social-network li {display: inline-block; margin: 0 5px;}


/* footer social icons */
.social-network a.icoRss {background-color: #F56505;}
.social-network a.icoFacebook {background-color:#3B5998;}
.social-network a.icoTwitter {background-color:#33ccff; }
.social-network a.icoInstagram {background-color:#125688; }
.social-network a.icoGoogle {background-color:#BD3518; }
.social-network a.icoVimeo {background-color:#0590B8; }
.social-network a.icoLinkedin {background-color:#007bb7; }

.social-circle li a {display:inline-block; position:relative; margin:10px auto; border-radius:50%; text-align:center; width: 30px; height: 30px; font-size:15px;}
.social-circle li a:hover i, .triggeredHover {

	transform: rotate(360deg);
	transition: all 0.2s;
}

.social-circle li i {margin:0; line-height:30px; text-align: center;}
.social-circle i {color: #fff; -webkit-transition: all 0.8s; -moz-transition: all 0.8s; -o-transition: all 0.8s; -ms-transition: all 0.8s; transition: all 0.8s; }

.divider{
	position:relative;
	margin:5px 0px 30px 0;
	&::before{content:'';border:1px solid #5b6585;position:absolute;left:0px;right:0px;top:18px;z-index:1;}
	h1,h2,h3,h4{color:$brand-primary;display:inline-block;margin:0px;background:#fff;position:relative;z-index:2;padding:0px 20px;}

	h3{margin-top: 6px;}
	h4{margin-top: 9px;}
	p{font-size:19px;margin:40px 0px;}
	&.text-center{margin:30px 0px;}
	&.text-left{ h1,h2,h3{padding-left:0;}}
}

[type="checkbox"] {
	&:not(:checked), &:checked {
		position: absolute;
		left: -9999px;
	}
	&:not(:checked) + label, &:checked + label {
		position: relative;
		padding-left: 25px;
		cursor: pointer;
	}
	&:not(:checked) + label:before, &:checked + label:before {
		content: '';
		position: absolute;
		left: 0;
		top: 2px;
		width: 23px;
		height: 23px;
		border: 1px solid #aaa;
		background: #f8f8f8;
		border-radius: 3px;
		box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3);
	}
	&:not(:checked) + label:after, &:checked + label:after {
		content: '✔';
		position: absolute;
		top: 0;
		left: 4px;
		font-size: 29px !important;
		color: #09ad7e;
		transition: all .2s;
	}
	&:not(:checked) + label:after {
		opacity: 0;
		transform: scale(0);
	}
	&:checked + label:after {
		opacity: 1;
		transform: scale(1);
	}
	&:disabled {
		&:not(:checked) + label:before {
			box-shadow: none;
			border-color: #bbb;
			background-color: #ddd;
		}
		&:checked + label {
			&:before {
				box-shadow: none;
				border-color: #bbb;
				background-color: #ddd;
			}
			&:after {
				color: #999;
			}
		}
		+ label {
			color: #aaa;
		}
	}
	&:checked:focus + label:before, &:not(:checked):focus + label:before {
		border: 1px dotted blue;
	}
}

label.check{
	font-size:23px !important;
	&:hover:before {
		border: 1px solid #4778d9 !important;
	}
	span{margin-left:6px;line-height:28px}
}

.form-group{
	label.check{
		margin-top:-10px;padding-top:7px;
	}
	[type="checkbox"] {
		&:not(:checked) + label:before, &:checked + label:before{top:10px !important}
	}
}

.mbc-facebook {color: $mbc-facebook; }
.mbc-facebook-bg {background-color: $mbc-facebook; color:#fff}
.mbc-facebook-bg:hover {background-color: darken($mbc-facebook,10%); color:#fff !important}

.mbc-twitter {color: $mbc-twitter;}
.mbc-twitter-bg {background-color: $mbc-twitter; color:#fff}
.mbc-twitter-bg:hover {background-color: darken($mbc-twitter,10%); color:#fff !important}

.gallery-view {
	-moz-column-width: 18em;
	-webkit-column-width: 18em;
	-moz-column-gap: .5em;
	-webkit-column-gap: .5em;
	.item {
		display: inline-block;
		padding: .25rem;
		width: 100%;
		box-sizing: border-box !important;
		&:before, &:after {
			box-sizing: border-box !important;
		}
		a{
			position: relative;
			display: block;
			.name{
				background:$gray-lighter;
				display: block;
				color:$brand-primary;
				font-size:18px;
				text-align: center;
				padding:8px 0px;
			}
		}
	}
}

/**
 * customizing
 */
.breadcrumb{
	color:$brand-primary;
	font-size:17px;font-weight:500;
	margin-top:20px;
	// text-align: center;
	li{
		+ li:before{
			content:'/' !important;
		}
		a{color:inherit}
	}
}

.useraction{
	color:$brand-primary;
	font-size:17px;font-weight:500;
	margin-top:20px;
}


.fb_iframe_widget,iframe.twitter-follow-button{vertical-align: middle;}
.ig-b- { display: inline-block; transition: none }
.ig-b- img { visibility: hidden; }
.ig-b-:hover { background-position: 0 -60px; } 
.ig-b-:active { background-position: 0 -120px; }
.ig-b-v-24 { width: 137px; height: 24px; background: url(//badges.instagram.com/static/images/ig-badge-view-sprite-24.png) no-repeat 0 0; }
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
.ig-b-v-24 { background-image: url(//badges.instagram.com/static/images/ig-badge-view-sprite-24@2x.png); background-size: 160px 178px; } }

@import "responsive";