.fileinput-button {
  position: relative;
  overflow: hidden;
  display: inline-block;
  input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
  }
}

/* Fixes for IE < 8 */
@media screen\9  {
  .fileinput-button input {
    filter: alpha(opacity = 0);
    font-size: 100%;
    height: 100%;
  }
}