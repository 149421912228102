$brand-primary: #3b435d;
$brand-primary-pastel: #394a6f;
$brand-secondary: #f63636;

$navbar-height: 90px;
$navbar-inverse-bg: transparent;
$navbar-inverse-brand-color: $brand-primary;
$navbar-inverse-brand-hover-color: $brand-secondary;
$navbar-inverse-link-color: #fff;
$navbar-inverse-link-hover-color: $brand-secondary;

$navbar-inverse-toggle-hover-bg: $brand-secondary;
$navbar-inverse-toggle-border-color: #fff;
$navbar-inverse-toggle-icon-bar-bg: #fff;

$navbar-margin-bottom: 0px;

$well-border : transparent;

$input-height-large: 55px;

$input-border-radius:0px;

$breadcrumb-active-color:$brand-secondary !default;
$breadcrumb-bg:transparent;
$breadcrumb-padding-vertical:0px;
$breadcrumb-padding-horizontal:0px;

$carousel-indicator-active-bg:$brand-primary;
$carousel-indicator-border-color:$brand-primary;
$carousel-control-color:$brand-primary;

$btn-border-radius-large:0px;